@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "League Spartan", sans-serif;
  }
}
@layer utilities {
  .scrollbar {
    scrollbar-color: #7b788a #1e1c27;
    scrollbar-width: thin;
  }

  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #1e1c27;
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #7b788a;
    border-radius: 100vh;
    border: 1px solid #f6f7ed;
  }

  .scrollbar:hover::-webkit-scrollbar-thumb {
    background: #7b788a;
  }

  .container-background {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0.05) 100%
    );
  }

  .container-background-graph-expanded {
    /* background: #2b2837; */
    background: linear-gradient(180deg, #312e3e, #232132);
  }
  .container-background-dialog {
    /* background: #2b2837; */
    background: linear-gradient(180deg, #312e3e, #2b2837);
  }

  .dropdown-custom {
    background: linear-gradient(
      180.21deg,
      rgba(81, 77, 102, 0.5) -6.35%,
      rgba(61, 58, 79, 0.5) 82.12%
    );
    backdrop-filter: blur(15px);
    border-radius: 15px;
  }

  .hero-text {
    background: linear-gradient(
      180deg,
      #ffffff 29.57%,
      #dad7ec 76.46%,
      #c3bdde 100%
    );
    background-clip: text;
  }

  .selected-protocol {
    background: rgba(191, 177, 255, 0.15);
    border-radius: 10px;
  }

  .shadow-protocols {
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1),
      rgba(255, 255, 255, 0.05)
    );
    mask-size: 45%;
    mask-repeat: no-repeat;
  }

  #chartjs-tooltip {
    /* line-height: normal;
    opacity: 1;
    padding: 0.3em;
    pointer-events: none;
    position: fixed;
    text-align: center; */
    transform: translate(-50%, 0);
    transition: all 0.1s ease;
    background: linear-gradient(
      180.21deg,
      rgba(81, 77, 102, 0.5) -6.35%,
      rgba(61, 58, 79, 0.5) 82.12%
    );
    backdrop-filter: blur(15px);
  }
}

@layer components {
  .sidebar-item {
    @apply w-[60px] h-[60px] flex flex-col mt-[24px] 
    rounded-md text-black hover:text-white border-2 
    hover:border-0 hover:bg-gradient-to-r justify-center 
    items-center hover:from-primary hover:to-[#B7156B] cursor-pointer duration-300 ease-linear;
  }
  .sidebar-item-active {
    @apply w-[60px] h-[60px] flex flex-col mt-[24px] 
    rounded-md text-black text-white 
    border-0 bg-gradient-to-r justify-center 
    items-center from-primary to-[#B7156B];
  }

  .date-filter {
    @apply cursor-pointer px-[13px] h-[33px] md:ml-[13px] py-[8px] border-2 rounded-[4px] text-[14px] flex items-center justify-center;
  }
  .date-filter-active {
    @apply cursor-pointer px-[13px] h-[33px] md:ml-[13px] bg-active py-[8px] border-2 rounded-[4px] text-[14px] flex items-center justify-center;
  }
}
